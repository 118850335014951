import classNames from "classnames";
import React, { memo, FC } from "react";

export interface Banner {
  title: string;
  description: string;
  descriptionTextColor: string;
  buttonColor: string;
  buttonTextColor: string;
  image: string;
  backgroundColor: string;
}

interface HeroProps {
  banners: Banner[] | undefined;
  isNavigationMenuTypeTop: boolean;
}

const SINGLE_BANNER_HEIGHT = "400px";
const MULTI_BANNER_HEIGHT = "320px";

const Hero: FC<HeroProps> = memo(({ banners, isNavigationMenuTypeTop }) => {
  return (
    <div className={classNames("flex space-x-6 pt-6")}>
      {banners?.map((banner, index) => (
        <div
          key={index}
          className={classNames(
            "relative flex flex-1 overflow-hidden rounded-2xl",
          )}
          style={{
            background: banner.backgroundColor,
            height:
              banners.length > 1 ? MULTI_BANNER_HEIGHT : SINGLE_BANNER_HEIGHT,
          }}
        >
          <div
            className={classNames(
              "z-10 flex flex-col items-start justify-center space-y-4",
              banners.length > 1 ? "w-72 pl-6" : "w-[522px] pl-10",
            )}
          >
            <div className="text-[32px] font-bold leading-tight text-white">
              {banner.title}
            </div>
            <div>
              <p
                className={classNames("text-[17px]")}
                style={{
                  color: banner.descriptionTextColor,
                }}
              >
                {banner.description}
              </p>
            </div>
            <button
              className={classNames("rounded-lg px-6 py-3")}
              style={{
                backgroundColor: banner.buttonColor,
                color: banner.buttonTextColor,
              }}
            >
              ORDER NOW
            </button>
          </div>
          {isNavigationMenuTypeTop ? (
            <img
              className={classNames("absolute")}
              style={{
                height:
                  banners.length > 1
                    ? MULTI_BANNER_HEIGHT
                    : SINGLE_BANNER_HEIGHT,
                right: banners.length > 1 ? -120 : 120,
              }}
              src={require(`../../assets/${banner.image}`)}
              alt="banner"
            />
          ) : (
            <img
              className={classNames("absolute")}
              style={{
                height:
                  banners.length > 1
                    ? MULTI_BANNER_HEIGHT
                    : SINGLE_BANNER_HEIGHT,
                right: banners.length > 1 ? -140 : 20,
              }}
              src={require(`../../assets/${banner.image}`)}
              alt="banner"
            />
          )}
        </div>
      ))}
    </div>
  );
});

export default Hero;
