import classNames from "classnames";
import React, { memo, FC, useState } from "react";
import AddToCartSvg from "../../assets/AddToCartSvg";
import { FunnelIcon, ShareIcon } from "@heroicons/react/24/outline";

interface ProductListProps {
  isProductListTypeLeft: boolean;
}

const ProductList: FC<ProductListProps> = memo(({ isProductListTypeLeft }) => {
  const [filterList] = useState([
    {
      label: "Fashion Accessories",
      selected: false,
    },
    {
      label: "Gifts",
      selected: false,
    },
    {
      label: "Party Disposables",
      selected: false,
    },
    {
      label: "Party Decoration",
      selected: false,
    },
    {
      label: "Party Supplies",
      selected: false,
    },
    {
      label: "Costumes",
      selected: false,
    },
    {
      label: "Beauty and Personal Care",
      selected: false,
    },
    {
      label: "Sports and Fitness",
      selected: false,
    },
  ]);

  if (isProductListTypeLeft) {
    return (
      <div className={classNames("mt-8 flex space-x-8 pb-14")}>
        <div className="w-1/4">
          <p className="text-lg font-bold">Categories</p>
          <div className="mt-3 space-y-2">
            <div className="flex cursor-pointer items-center space-x-2 rounded-lg border border-[#E2E8F0] px-4 py-3">
              <FunnelIcon width={18} className="text-gray-400" />
              <span>Filter</span>
            </div>
            {filterList.map((filter) => (
              <div
                key={filter.label}
                className="cursor-pointer rounded-lg border border-[#E2E8F0] px-4 py-3"
              >
                {filter.label}
              </div>
            ))}
          </div>
        </div>
        <div className="w-3/4">
          <p className="text-lg font-bold">Products</p>
          <div className="mt-3 space-y-3">
            {[
              {
                id: 1,
                title: "Adventurous Bucketeer Mug",
                description:
                  "Start your day with the Adventurous Bucketeer Mug, perfect for the coffee that fuels your morning adventures. Made of sturdy ceramic with a unique design, this mug will be your companion on all your explorations.",
                price: 19.99,
                image: "mug.png",
                imageAlt: "mug",
              },
              {
                id: 2,
                title: "Horizon Bucketeer Fleece",
                description:
                  "Brave the cold in style with the Horizon Bucketeer Fleece. Crafted with warm and comfortable fabric, this fleece is ideal for chilly moments during your travels and explorations.",
                price: 49.99,
                image: "fleece.png",
                imageAlt: "fleece",
              },
              {
                id: 3,
                title: "Bucketeer Explorations Sticker",
                description:
                  "Personalize your gear with the Bucketeer Explorations Sticker! Water-resistant and high-quality, this sticker is perfect for adding a touch of adventure to backpacks, notebooks, and other items.",
                price: 29.99,
                image: "sticker.png",
                imageAlt: "sticker",
              },
            ].map((product) => (
              <div
                key={product.id}
                className="flex overflow-hidden rounded-lg shadow-lg"
              >
                <img
                  src={require(`../../assets/${product.image}`)}
                  className="w-36 object-cover"
                  alt={product.imageAlt}
                />
                <div className="px-6 py-4">
                  <div>
                    <p className="text-xl font-bold">{product.title}</p>
                    <p className="my-2 text-base text-[#94A3B8]">
                      {product.description}
                    </p>
                    <p className="text-base font-bold">
                      Price: ${product.price}
                    </p>
                  </div>
                  <div className="mt-6 flex justify-between">
                    <button className="flex items-center p-2">
                      <AddToCartSvg />
                      <span className="ml-1 text-[#3162D6]">ADD TO CART</span>
                    </button>
                    <button className="p-2">
                      <ShareIcon width={18} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={classNames("mt-8 pb-14")}>
      <div>
        <p className="text-lg font-semibold">Categories</p>
        <div className="mt-2 flex justify-between">
          <div className="flex flex-wrap space-x-2">
            {filterList.slice(0, 6).map((filter) => (
              <div
                key={filter.label}
                className="cursor-pointer rounded-lg border border-[#E2E8F0] px-4 py-3"
              >
                {filter.label}
              </div>
            ))}
          </div>
          <div className="flex cursor-pointer items-center space-x-2 rounded-lg border border-[#E2E8F0] px-4 py-3">
            <FunnelIcon width={18} className="text-gray-400" />
            <span>Filter</span>
          </div>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-3 gap-8">
        {[
          {
            id: 1,
            title: "Bucketeer Explorations Sticker",
            description:
              "Personalize your gear with the Bucketeer Explorations Sticker! Water-resistant and high-quality, this sticker is perfect for adding a touch of adventure to backpacks, notebooks, and other items.",
            price: 29.99,
            image: "sticker.png",
            imageAlt: "product3",
          },
          {
            id: 2,
            title: "Horizon Bucketeer Fleece",
            description:
              "Brave the cold in style with the Horizon Bucketeer Fleece. Crafted with warm and comfortable fabric, this fleece is ideal for chilly moments during your travels and explorations.",
            price: 49.99,
            image: "fleece.png",
            imageAlt: "product2",
          },
          {
            id: 3,
            title: "Adventurous Bucketeer Mug",
            description:
              "Start your day with the Adventurous Bucketeer Mug, perfect for the coffee that fuels your morning adventures. Made of sturdy ceramic with a unique design, this mug will be your companion on all your explorations.",
            price: 12.99,
            image: "mug.png",
            imageAlt: "product1",
          },
          {
            id: 4,
            title: "Truckster Bucketeer Cap",
            description:
              "The Truckster Bucketeer cap is an essential accessory for those living life on the road.",
            price: 24.99,
            image: "cap.png",
            imageAlt: "cap",
          },
          {
            id: 5,
            title: "Adventure Seeker Bucketeer Bottle",
            description:
              "Stay hydrated during your adventures with the Adventure Seeker Bucketeer Bottle. Made of durable stainless steel with thermal insulation, this bottle keeps your drinks hot or cold while you explore new horizons.",
            price: 19.99,
            image: "bottle.png",
            imageAlt: "bottle",
          },
          {
            id: 6,
            title: "Custom Bucketeer Lanyard",
            description:
              "Take your explorer identity to another level with the Custom Bucketeer Lanyard. This versatile accessory allows customization, serving as the perfect complement for keys, badges, or even as a unique style item.",
            price: 8.99,
            image: "lanyard.png",
            imageAlt: "lanyard",
          },
        ].map((product) => (
          <div
            key={product.id}
            className="overflow-hidden rounded-xl border border-[#E2E8F0]"
          >
            <img
              src={require(`../../assets/${product.image}`)}
              className="h-64 w-full object-cover"
              alt={product.imageAlt}
            />
            <div className="px-6 py-4">
              <div>
                <p className="line-clamp-1 text-xl font-bold">
                  {product.title}
                </p>
                <p className="my-2 line-clamp-2 text-base text-[#94A3B8]">
                  {product.description}
                </p>
                <p className="text-base font-bold">Price: ${product.price}</p>
              </div>
              <div className="mt-4 flex justify-between">
                <button className="flex items-center p-2">
                  <AddToCartSvg />
                  <span className="ml-1 text-[#3162D6]">ADD TO CART</span>
                </button>
                <button className="p-2">
                  <ShareIcon width={18} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default ProductList;
