interface GitHubSvgProps {
  color?: string;
}

export default function GitHubSvg({ color }: GitHubSvgProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="image" clipPath="url(#clip0_2000_28078)">
        <path
          id="Vector"
          d="M14 0.347656C6.265 0.347656 0 6.61616 0 14.3477C0 20.5345 4.011 25.781 9.5725 27.6302C10.2725 27.762 10.5292 27.3292 10.5292 26.957C10.5292 26.6245 10.5175 25.7437 10.5117 24.577C6.61733 25.4217 5.796 22.6987 5.796 22.6987C5.159 21.0828 4.2385 20.6512 4.2385 20.6512C2.97033 19.7832 4.3365 19.8007 4.3365 19.8007C5.74233 19.8987 6.48083 21.2427 6.48083 21.2427C7.72917 23.3835 9.758 22.7652 10.5583 22.407C10.6843 21.5017 11.0448 20.8845 11.445 20.5345C8.33583 20.1845 5.068 18.9805 5.068 13.6162C5.068 12.0878 5.6105 10.8395 6.50883 9.85949C6.35133 9.50599 5.87883 8.08266 6.63133 6.15416C6.63133 6.15416 7.80383 5.77849 10.4813 7.58916C11.6013 7.27766 12.7913 7.12366 13.9813 7.11666C15.1713 7.12366 16.3613 7.27766 17.4813 7.58916C20.1413 5.77849 21.3138 6.15416 21.3138 6.15416C22.0663 8.08266 21.5938 9.50599 21.4538 9.85949C22.3463 10.8395 22.8888 12.0878 22.8888 13.6162C22.8888 18.9945 19.6163 20.1787 16.5013 20.5228C16.9913 20.9428 17.4463 21.8015 17.4463 23.1128C17.4463 24.9865 17.4288 26.4915 17.4288 26.9465C17.4288 27.314 17.6738 27.7515 18.3913 27.6115C23.9925 25.7752 28 20.5252 28 14.3477C28 6.61616 21.7315 0.347656 14 0.347656Z"
          fill={color ? color : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2000_28078">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
