import React, { useEffect, useState } from "react";
import {
  getBKTClient,
  defineBKTConfig,
  defineBKTUser,
  initializeBKTClient,
} from "@bucketeer/js-client-sdk";
import NavigationMenu from "./components/NavigationMenu";
import Hero, { Banner } from "./components/Hero";
import ProductList from "./components/ProductList";
import Footer from "./components/Footer";
import Chat from "./components/Chat";
import WelcomeBackHeader from "./components/WelcomebackHeader";

const { REACT_APP_DEMO_API_ENDPOINT, REACT_APP_DEMO_API_KEY } = process.env;

const CHAT_FEATURE_ID = "chat-button";
const NAVIGATION_FEATURE_ID = "navigation-menu";
const PRODUCT_LIST_FEATURE_ID = "product-list";
const TOP_BANNERS_FEATURE_ID = "top-banners";

const FEATURE_TAG = "web";

const config = defineBKTConfig({
  apiEndpoint: REACT_APP_DEMO_API_ENDPOINT || "",
  apiKey: REACT_APP_DEMO_API_KEY || "",
  featureTag: FEATURE_TAG,
  appVersion: "0.0.1",
  fetch: window.fetch,
});

const user = defineBKTUser({
  id: "bucketeer-demo",
});

function App() {
  const [showChat, setShowChat] = useState<boolean | undefined>();
  const [navigationMenuType, setNavigationMenuType] = useState<
    string | undefined
  >();
  const [productListType, setProductListType] = useState<string | undefined>();
  const [banners, setBanners] = useState<Banner[] | undefined>();

  useEffect(() => {
    // Initialize the client
    const initialFetchPromise = initializeBKTClient(config, user);
    initialFetchPromise
      .then(() => {
        console.log("initialization completed");

        const chatValue = getBKTClient()?.booleanVariation(
          CHAT_FEATURE_ID,
          true,
        );
        setShowChat(chatValue);

        const navigationValue = getBKTClient()?.stringVariation(
          NAVIGATION_FEATURE_ID,
          "left",
        );
        setNavigationMenuType(navigationValue);

        const productListValue = getBKTClient()?.stringVariation(
          PRODUCT_LIST_FEATURE_ID,
          "leftCategories",
        );
        setProductListType(productListValue);

        const bannersValue = getBKTClient()?.jsonVariation(
          TOP_BANNERS_FEATURE_ID,
          [],
        );
        setBanners(bannersValue);
      })
      .catch((error) => {
        console.log(`initialization failed: ${error}`);
      });
  }, []);

  return (
    <div>
      {navigationMenuType === "top" ? (
        <>
          <NavigationMenu isNavigationMenuTypeTop={true} />
          <div className="wrapper">
            <Hero banners={banners} isNavigationMenuTypeTop={true} />
            <ProductList
              isProductListTypeLeft={productListType === "leftCategories"}
            />
          </div>
        </>
      ) : (
        <div className="bg-gradient-to-r from-primary-light from-50% to-white to-50%">
          <div className="wrapper flex">
            <NavigationMenu isNavigationMenuTypeTop={false} />
            <div className="w-4/5 bg-white px-6 py-8">
              <WelcomeBackHeader />
              <Hero banners={banners} isNavigationMenuTypeTop={false} />
              <ProductList
                isProductListTypeLeft={productListType === "leftCategories"}
              />
            </div>
          </div>
        </div>
      )}
      <Footer />
      {showChat && <Chat />}
    </div>
  );
}

export default App;
