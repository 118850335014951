import React from "react";

export default function AddToCart() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="AddShoppingCart">
        <path
          id="Vector"
          d="M9.16671 7.49998H10.8334V4.99998H13.3334V3.33331H10.8334V0.833313H9.16671V3.33331H6.66671V4.99998H9.16671V7.49998ZM5.83337 15C4.91671 15 4.17504 15.75 4.17504 16.6666C4.17504 17.5833 4.91671 18.3333 5.83337 18.3333C6.75004 18.3333 7.50004 17.5833 7.50004 16.6666C7.50004 15.75 6.75004 15 5.83337 15ZM14.1667 15C13.25 15 12.5084 15.75 12.5084 16.6666C12.5084 17.5833 13.25 18.3333 14.1667 18.3333C15.0834 18.3333 15.8334 17.5833 15.8334 16.6666C15.8334 15.75 15.0834 15 14.1667 15ZM5.97504 12.2916L6.00004 12.1916L6.75004 10.8333H12.9584C13.5834 10.8333 14.1334 10.4916 14.4167 9.97498L17.6334 4.13331L16.1834 3.33331H16.175L15.2584 4.99998L12.9584 9.16665H7.10837L7.00004 8.94165L5.13337 4.99998L4.34171 3.33331L3.55837 1.66665H0.833374V3.33331H2.50004L5.50004 9.65831L4.37504 11.7C4.24171 11.9333 4.16671 12.2083 4.16671 12.5C4.16671 13.4166 4.91671 14.1666 5.83337 14.1666H15.8334V12.5H6.18337C6.07504 12.5 5.97504 12.4083 5.97504 12.2916Z"
          fill="#666CFF"
        />
      </g>
    </svg>
  );
}
