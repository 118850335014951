import {
  ChatBubbleOvalLeftEllipsisIcon,
  ExclamationCircleIcon,
  FaceSmileIcon,
  LinkIcon,
  PaperAirplaneIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { memo, FC, useState } from "react";

interface ChatProps {}

const Chat: FC<ChatProps> = memo(() => {
  const [isChatWidgetOpen, setIsChatWidgetOpen] = useState(false);
  return (
    <div>
      {isChatWidgetOpen && (
        <div className="fixed bottom-[102px] right-6 flex h-[700px] w-[375px] flex-col justify-between overflow-hidden rounded-xl bg-white shadow-xl">
          <div className="flex justify-between bg-primary p-6 text-white">
            <span className="text-2xl">New Chat</span>
            <XMarkIcon
              width={24}
              className="cursor-pointer"
              onClick={() => setIsChatWidgetOpen(false)}
            />
          </div>
          <div className="flex h-12 border-t px-6">
            <input
              placeholder="Message..."
              className="h-full flex-1 px-4 outline-none"
            />
            <div className="flex space-x-4">
              <LinkIcon width={16} className="cursor-pointer text-gray-500" />
              <FaceSmileIcon width={16} color="cursor-pointer text-gray-400" />
              <ExclamationCircleIcon
                width={18}
                color="cursor-pointer text-gray-400"
              />
              <PaperAirplaneIcon
                width={18}
                color="cursor-pointer text-gray-600"
              />
            </div>
          </div>
        </div>
      )}
      <div
        onClick={() => setIsChatWidgetOpen(!isChatWidgetOpen)}
        className="fixed bottom-6 right-6 flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-primary shadow-xl"
      >
        {isChatWidgetOpen ? (
          <XMarkIcon width={30} color="#fff" />
        ) : (
          <ChatBubbleOvalLeftEllipsisIcon width={30} color="#fff" />
        )}
      </div>
    </div>
  );
});

export default Chat;
