interface TwitterSvgProps {
  color?: string;
}

export default function TwitterSvg({ color }: TwitterSvgProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="image">
        <path
          id="Vector"
          d="M27.9452 5.33161C26.8969 5.79315 25.7864 6.0978 24.6493 6.23577C25.8465 5.51623 26.7428 4.38782 27.1728 3.05894C26.0633 3.70644 24.8337 4.17777 23.5247 4.44027C22.6611 3.51677 21.5167 2.90426 20.2693 2.69786C19.0218 2.49145 17.7411 2.7027 16.6261 3.29879C15.511 3.89489 14.624 4.84248 14.1027 5.99442C13.5815 7.14635 13.4551 8.43817 13.7433 9.66927C8.97167 9.44411 4.74483 7.15161 1.91333 3.68894C1.39859 4.56372 1.13011 5.56147 1.13633 6.57644C1.13633 8.57144 2.15133 10.3249 3.689 11.3551C2.77767 11.3261 1.88647 11.0797 1.08967 10.6364V10.7064C1.08915 12.0322 1.54731 13.3174 2.38642 14.3438C3.22553 15.3703 4.39392 16.0748 5.69333 16.3379C4.85135 16.5635 3.96948 16.5974 3.11267 16.4371C3.48148 17.5781 4.19727 18.5755 5.16018 19.2902C6.12309 20.0048 7.28508 20.4011 8.484 20.4236C6.45345 22.0172 3.94622 22.8821 1.365 22.8794C0.91 22.8794 0.456167 22.8526 0 22.8013C2.63166 24.4863 5.6916 25.3808 8.8165 25.3784C19.3783 25.3784 25.1475 16.6331 25.1475 9.06261C25.1475 8.81761 25.1475 8.57261 25.13 8.32761C26.257 7.51661 27.2291 6.50973 28 5.35494L27.9452 5.33161Z"
          fill={color ? color : "white"}
        />
      </g>
    </svg>
  );
}
