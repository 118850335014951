import { BellIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { memo, FC } from "react";

interface WelcomeBackHeaderProps {}

const WelcomeBackHeader: FC<WelcomeBackHeaderProps> = memo(() => {
  return (
    <div className="flex justify-between">
      <div>
        <p className="text-2xl font-bold">Welcome to Bucketeer Demo Website</p>
        <p className="font text-sm text-[#555555]">
          Here you will find many Bucketeer products.
        </p>
      </div>
      <div className="flex space-x-4 ">
        <div className="relative">
          <MagnifyingGlassIcon
            width={22}
            className="absolute left-4 top-1/2 -translate-y-1/2"
          />
          <input
            placeholder="Search"
            className=" w-[430px] rounded-lg border border-[#CBD5E1] py-3 pl-12 pr-4 placeholder-[#CBD5E1]"
          />
        </div>
        <div className="flex h-12 w-12 items-center justify-center rounded-lg border border-[#CBD5E1]">
          <BellIcon width={22} />
        </div>
      </div>
    </div>
  );
});

export default WelcomeBackHeader;
