import React, { memo, FC } from "react";

interface AppLogoProps {}

const AppLogo: FC<AppLogoProps> = memo(() => {
  return (
    <div className="flex">
      <img src={require("../../assets/logo.png")} className="w-36" alt="logo" />
      <span className="text-[#D61C8D] font-semibold ml-2 text-sm mt-1">
        SHOP
      </span>
    </div>
  );
});

export default AppLogo;
