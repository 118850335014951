import React, { memo, FC } from "react";
import GitHubSvg from "../../assets/GithubSvg";
import SlackSvg from "../../assets/SlackSvg";
import TwitterSvg from "../../assets/TwitterSvg";
import Slack2Svg from "../../assets/Slack2Svg";

interface FooterProps {}

const Footer: FC<FooterProps> = memo(() => {
  return (
    <div>
      <div className="bg-primary py-10">
        <div className="wrapper flex justify-between">
          {[
            {
              id: 1,
              icon: <GitHubSvg />,
              title: "Contributions welcome!",
              p1: "We value every contribution.",
              p2: "Join us on GitHub to learn how you can help.",
            },
            {
              id: 2,
              icon: <SlackSvg />,
              title: "Join the conversation!",
              p1: "Have a question?",
              p2: "Learn more by talking with other contributors in",
              p3: (
                <>
                  Cloud Native Slack via{" "}
                  <span className="mr-1 text-[#DE0B99]">bucketeer</span>
                  channel.
                </>
              ),
            },
            {
              id: 3,
              icon: <TwitterSvg />,
              title: "Follow us on Twitter!",
              p1: "Don’t miss out!",
              p2: "Follow us for feature announcements and other",
              p3: "news.",
            },
          ].map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center text-center text-white"
            >
              {item.icon}
              <p className="mt-4 text-3xl font-medium">{item.title}</p>
              <div className="mt-2">
                <p className="leading-snug">{item.p1}</p>
                <p className="leading-snug">{item.p2}</p>
                <p className="leading-snug">{item.p3}</p>
              </div>
              <button className="mt-4">Read More...</button>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-primary-light">
        <div className="wrapper flex items-center justify-between py-4">
          <div className="text-sm">
            <span className="text-[#57606A]">
              ©2023 The Bucketeer Authors All Rights Reserved.
            </span>
            <span className="ml-1 text-primary">Privacy Policy</span>
          </div>
          <div className="flex space-x-3">
            <div className="cursor-pointer">
              <GitHubSvg color="#5D3597" />
            </div>
            <Slack2Svg />
            <TwitterSvg color="#5D3597" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default Footer;
