import React, { memo, FC, useState } from "react";
import {
  ShoppingCartIcon,
  UserCircleIcon,
  MagnifyingGlassIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import AppLogo from "../AppLogo/AppLogo";

interface NavigationMenuProps {
  isNavigationMenuTypeTop: boolean;
}

const NavigationMenu: FC<NavigationMenuProps> = memo(
  ({ isNavigationMenuTypeTop }) => {
    const [menuList, setMenuList] = useState([
      {
        label: "Overview",
        selected: true,
      },
      {
        label: "Products",
        selected: false,
      },
      {
        label: "Customers",
        selected: false,
      },
      {
        label: "Orders",
        selected: false,
      },
      {
        label: "Checkout",
        selected: false,
      },
      {
        label: "Help",
        selected: false,
      },
    ]);

    if (isNavigationMenuTypeTop) {
      return (
        <nav className="bg-primary-light pt-8">
          <div className="wrapper">
            <div className="flex items-center space-x-4 pb-4">
              <AppLogo />
              <div className="relative flex-1">
                <MagnifyingGlassIcon
                  width={22}
                  className="absolute left-4 top-1/2 -translate-y-1/2"
                />
                <input
                  placeholder="Search for products and more"
                  className="w-full rounded-lg border border-[#CBD5E1] py-3 pl-12 pr-4 placeholder-[#CBD5E1]"
                />
              </div>
              <ShoppingCartIcon width={22} className="text-primary" />
              <div className="flex">
                <UserCircleIcon width={22} className="text-primary" />
                <div className="ml-2 text-[#555555]">
                  <button className="font-semibold">Log in</button>
                  <span className="mx-1">or</span>
                  <button className="font-semibold">register</button>
                  <p className="leading-none">to continue</p>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <ul className="flex">
                {menuList.map((menu) => (
                  <li
                    key={menu.label}
                    className={classNames(
                      "flex-1 cursor-pointer py-3 text-center text-primary",
                      menu.selected
                        ? "border-b-2 border-primary"
                        : "text-opacity-80",
                    )}
                    onClick={() =>
                      setMenuList(
                        menuList.map((m) => ({
                          ...m,
                          selected: m.label === menu.label,
                        })),
                      )
                    }
                  >
                    {menu.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      );
    }

    return (
      <nav className="flex w-1/5 flex-col justify-between bg-primary-light p-6">
        <div>
          <AppLogo />
          <div className="mt-8">
            <p className="text-xs font-bold opacity-70">MANAGEMENT</p>
          </div>
          <ul className="mt-4 space-y-1">
            {menuList.map((menu) => (
              <li
                key={menu.label}
                className={classNames(
                  "flex-1 cursor-pointer rounded-lg p-3 text-primary",
                  menu.selected && "bg-primary text-white",
                )}
                onClick={() =>
                  setMenuList(
                    menuList.map((m) => ({
                      ...m,
                      selected: m.label === menu.label,
                    })),
                  )
                }
              >
                {menu.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex items-center space-x-2">
            <img
              src={require("../../assets/profile-pic.png")}
              alt="profile pic"
              className=" w-12"
            />
            <div>
              <p className="text-sm">Guy Hawkins</p>
              <p className="text-sm leading-none text-[#94A3B8]">
                guy@gmail.com
              </p>
            </div>
          </div>
          <ChevronDownIcon width={18} />
        </div>
      </nav>
    );
  },
);

export default NavigationMenu;
